body {
  margin: 0;
  font-family: 'PotencyText';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.gradient-bg {
    background: rgb(208,18,150);
    background: linear-gradient(180deg, rgba(208,18,150,1) 0%, rgba(96,48,248,1) 100%);
}

.animate-pulse {
    animation: pulse 2s cubic-bezier(.4,0,.6,1) infinite;
}

@font-face {
    font-family: 'PotencyHeader';
    src: local('PotencyHeader'), url(./assets/fonts/SuisseWorks-Bold.otf);
}

@font-face {
    font-family: 'PotencySubHeader';
    src: local('PotencySubHeader'), url(./assets/fonts/SuisseBPSerif.otf);
}

@font-face {
    font-family: 'PotencyText';
    src: local('PotencyText'), url(./assets/fonts/SuisseBPIntLight.ttf);
}

@font-face {
    font-family: 'PotencyMenuText';
    src: local('PotencyMenuText'), url(./assets/fonts/SuisseBPSerifMedium.otf);
}

h1 {
    margin-bottom: 20px;
}

h6 {
    margin-bottom: 15px;
}

p {
    margin-bottom: 10px;
}

ul {
    margin-bottom: 10px;
}

ul li p {
    margin-bottom: 0px;
}
